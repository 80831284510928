import * as React from "react"
import styled from '@emotion/styled'
import { graphql, Link } from 'gatsby';
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Layout from '../components/Layout/Layout';
import { PageTitleHeader } from '../globalStyles';

import CakeRentals from "../content/rentals.json";

const Container = styled.div`
  margin: 3rem auto;
  max-width: 1280px;
  padding: 0 20px;
`

const RentalDescriptionContainer = styled.div`
  margin: 10px auto;
  max-width: 960px;
  padding: 0 20px;
`

const CakeStandRentalsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const CakeContainer = styled.div`
  width: calc(50% - 10px);
  margin: 10px auto;
  text-align: center;
  @media screen and (min-width: 960px) {
    width: calc(33% - 20px);
  }

  img {
    margin-top: 25px;
  }
  p{
    margin-top: 5px;
    margin-bottom: 5px;
  }
`

const CakeSizeText = styled.p`
  font-family: 'Arial';
  font-weight: 200;
`


export const queryMobile = graphql`
  query {
    cakeImages: allFile(
      filter: {
        sourceInstanceName: {eq: "images"}, 
        relativeDirectory: {eq: "rentals"}
      },
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          id
          name
          relativePath
          relativeDirectory
          publicURL
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: CONSTRAINED
              quality: 90
              formats: WEBP
              aspectRatio: 0.75
              transformOptions: { fit: COVER, cropFocus: NORTH }
            )
          }
        }
      }
    }
  }
`

const Rentals = ({ data }) => {
  let pathname = typeof window !== "undefined" ? window.location.pathname : ""

  return (
    <Layout
      title="Rentals"
      description="View cake stand rentals available for your big day!"
      pathname={pathname}
    >
      <Container>

        <PageTitleHeader> Rentals </PageTitleHeader>

        <RentalDescriptionContainer>
          <p>
            Here you can find different cake stand rentals.
          </p>

          <p>
          Cake stand must be returned within 3 days (72 hours) from the event day. <br></br>
          A $50 refundable deposit is required upon cake stand rental. <br></br>
          The deposit will be refunded once the stand is returned in its original condition. 
          </p>
        </RentalDescriptionContainer>

        <CakeStandRentalsContainer>
          { CakeRentals.map((rental) => {
            console.log(data);
            const cakeImage = data.cakeImages.edges.find(node => node.node.name == rental.image_name);
              return <CakeContainer key={rental.name}>
                <GatsbyImage
                  image={cakeImage.node.childImageSharp.gatsbyImageData}
                  alt={rental.alt}
                  placeholder="blurred"
                  layout="constrained"
                  quality={85}
                  className="desktop"
                />
                <p><b> {rental.name} </b> </p>
                <p> ${rental.price} </p>
              </CakeContainer>
            }) }
        </CakeStandRentalsContainer>

      </Container>
    </Layout>
  )
}

export default Rentals

